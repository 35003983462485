<template>
  <Dialog v-model:visible="display" :header="form.province+form.city+form.month+'月历史气象记录'"
          @hide="$emit('close')" :modal="true">
    <div class="content">
      <div class="p-field p-grid">
        <label class="p-col-fixed" style="width:3.5vw;display: flex;justify-content: flex-end;"></label>
        <div style="width: 43vw;margin-left: 8px;border-radius: 3px;">
          <div class="p-col coleess" style="padding: 1vw 0 1vw 0">
            <div class="coleess">
              <label class="p-col-fixed" style="margin-top: 8px;">平均温度：</label>
              <label v-if="data&&data[3]" class="p-col-fixed" style="margin-top: 8px;">{{ data
                  ? data[3]
                  : '-' }}°C</label>
            </div>
            <div class="colee">
              <label class="p-col-fixed" style="margin-top: 8px;">总降雨量：</label>
              <label v-if="data&&data[8]" class="p-col-fixed" style="margin-top: 8px;">{{ data
                  ? data[8]
                  : '-' }}</label>
            </div>
          </div>
          <div class="p-col coleess" style="padding: 1vw 0 1vw 0">
            <div class="coleess">
              <label class="p-col-fixed" style="margin-top: 8px;">最高温度：</label>
              <label v-if="data&&data[4]" class="p-col-fixed" style="margin-top: 8px;">{{ data
                  ? data[4]
                  : '-' }}°C</label>
            </div>
            <div class="colee">
              <label class="p-col-fixed" style="margin-top: 8px;">平均可见度</label>
              <label v-if="data&&data[9]" class="p-col-fixed" style="margin-top: 8px;">{{ data
                  ? data[9]
                  : '-' }}Km</label>
            </div>
          </div>
          <div class="p-col coleess" style="padding: 1vw 0 1vw 0">
            <div class="coleess">
              <label class="p-col-fixed" style="margin-top: 8px;">最低温度：</label>
              <label v-if="data&&data[5]" class="p-col-fixed" style="margin-top: 8px;">{{ data
                  ? data[5]
                  : '-' }}°C</label>
            </div>
            <div class="coleess">
              <label class="p-col-fixed" style="margin-top: 8px;">最大可持续风速：</label>
              <label v-if="data&&data[12]" class="p-col-fixed" style="margin-top: 8px;">{{ data
                  ? data[12]
                  : '-' }}Km/h</label>
            </div>
          </div>
          <div class="p-col coleess" style="padding: 1vw 0 1vw 0">
            <div class="coleess">
              <label class="p-col-fixed" style="margin-top: 8px;">平均湿度：</label>
              <label v-if="data&&data[7]" class="p-col-fixed" style="margin-top: 8px;">{{ data
                  ? data[7]
                  : '-' }}%</label>
            </div>
            <div class="coleess">
              <label class="p-col-fixed" style="margin-top: 8px;">下雨总天数：</label>
              <label v-if="data&&data[13]" class="p-col-fixed" style="margin-top: 8px;">{{ data
                  ? data[13]
                  : '-' }}天</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blockUI" v-if="over" @click.stop="clickUI"></div>
    <div class="loadingdiv" id="jztq" v-if="over">加载天气数据中...</div>
  </Dialog>
</template>


<script>

export default {
  name: 'addOrUpdatesuggest1',
  data() {
    return {
      display: false,
      form: {
        province: null,
        city: null,
        month: null,
        type: 'month',
      },
      data: null,
      over: false,
      hasData: true,
    };
  },
  methods: {
    getList() {
      this.over = true;
      let that = this;
      this.$http('plan/getWeather', 'get', this.form, (result) => {
        //console.log(result.data)
        if (result.data) {
          let s = result.data.substring(1, result.data.length - 1);
          that.data = s.split(',');
          that.over = false;
          for (let i = 0; i < that.data.length; i++) {
            if(that.data[i].indexOf('\'')>-1){
              that.data[i] =that.data[i].replaceAll('\'','')
            }
            if (that.data[i].indexOf('\\') > -1) {
              that.data[i] = null;
            }
          }
        } else if (!result.data) {
          let jztqs = document.getElementById('jztq');
          jztqs.innerHTML = '获取数据失败';
        }

      });
    },
    init(province, city, month) {
      this.display = true;
      this.form.province = province;
      this.form.city = city;
      this.form.month = month;
      this.getList();
    },
    clickUI() {
      console.log('稍等');
    },
  },
};
</script>

<style scoped>
.content {
  width: 50vw;
  margin: 0 auto;
  font-size: 16px;
}

.colee {
  display: flex;
  justify-content: flex-start;
}

::v-deep(.p-dialog .p-dialog-content) {
  padding-top: 0;
}

.rig {
  margin-right: 2vw;
}

.coleess {
  display: flex;
  justify-content: space-between;
}

.blockUI {
  width: 100%;
  height: 80%;
  position: absolute;
  background: #111111;
  opacity: 0.3;
  top: 20%;
  left: 0;
  z-index: 999;
}

.loadingdiv {
  height: 40px;
  color: #ffffff;
  line-height: 40px;
  z-index: 999;
  position: absolute;
  font-size: 20px;
  display: flex;
  width: 200px;
  text-align: center;
  font-weight: 600;
  justify-content: center;
  top: calc(50% - 20px);
  left: calc(50% - 100px);

}
</style>
