<template>
  <div>
    <div style="display:flex;justify-content:space-between;margin-bottom:10px;">
      <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;" />
      <Button @click="back($router.go(-1))" label="返回" class=" p-button-sm p-button-outlined p-button-secondary" style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;" />
    </div>
    <div class="card p-grid">
      <div class="cont">
        <div style="margin: 0 auto;width: 100%;padding-top: 22px;">
          <div v-if="bool">
            <div class="p-fluid">
              <div class="p-field p-grid">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">规划名称:</label>
                <div class="p-col">
                  <InputText v-model="valuess" style="border: none;margin-left: -5px;" readonly="readonly"></InputText>
                  <label class="p-col-fixed" style="cursor:pointer;width:100px;margin-left: -5px;justify-content: flex-end;font-size: 12px;color: rgb(36, 189, 127);" @click="visibleLeft = true">
                    <i class="pi pi-eye"></i>
                    查看信息
                  </label>
                </div>
              </div>
              <div class="p-field p-grid">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">菌草品种:</label>
                <div class="p-col">
                  <Dropdown style="width: 150px;" placeholder="填写菌草品种" v-model="form.type" :options="pingzs" optionLabel="name" option-value="name"
                    :class="{'p-invalid':(v$.form.type.$invalid && submitted),'p-disabled':state===5}" />
                  <span v-if="v$.form.type.required.$invalid && submitted" style="font-size: 12px;color: red">请选择菌草品种！</span>
                </div>
              </div>
              <div class="p-field p-grid" style="margin-bottom: 0">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">种苗数量:</label>
                <div class="p-col colee">
                  <InputNumber :minFractionDigits="0" :useGrouping="false" v-model="form.num" style="width: 150px;" placeholder="填写数量" :class="{'p-invalid':(v$.form.num.$invalid && submitted),'p-disabled':state===5}">
                  </InputNumber>
                  <label class="p-col-fixed" style="margin: 5px 5px 0 5px;">株</label>
                </div>
              </div>
              <span v-if="v$.form.num.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 118px">请输入种苗数量！</span>
              <div class="p-field p-grid" style="margin-top: 31px;margin-bottom: 0">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">最佳种植时间:</label>
                <div class="p-col colee">
                  <Dropdown style="width: 150px;" placeholder="填写月份" v-model="form.bestTime" :options="yuef" optionLabel="name" option-value="id"
                    :class="{'p-invalid':(v$.form.bestTime.$invalid && submitted),'p-disabled':state===5}" />
                  <div class="tianq" style="color: #6EA056;" @click="Weatheraa" :style="{'pointer-events':!form.bestTime?'none':null}">
                    <i class="pi pi-sun"></i>
                  </div>
                </div>
              </div>
              <span v-if="v$.form.bestTime.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 118px">请选择最佳种植时间！</span>
              <div class="p-field p-grid" style="margin-top: 31px;">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">整地建议:</label>
                <div style="width: 79%; border: 1px solid #cccccc;margin-left: 5px;border-radius: 3px;  ">
                  <div class="p-field p-grid colee" style="margin-top: 15px;">
                    <label class="p-col-fixed" style="width:74px;justify-content: flex-end;margin-top: 4px;">行间距</label>
                    <div class="p-col colee">
                      <InputNumber :minFractionDigits="2" :useGrouping="false" style="width: 90px;" placeholder="填写尺寸" v-model="form.hangjianju"
                        :class="{'p-invalid':(v$.form.hangjianju.$invalid && submitted),'p-disabled':state===5}"></InputNumber>
                      <label class="p-col-fixed" style="width:74px;justify-content: flex-end;margin-top: 5px">cm</label>
                      <div class="p-col colee">
                        <label class="p-col-fixed" style="width:96px;justify-content: flex-end;margin-top: 8px;">沟宽(穴直径)</label>
                        <InputNumber :minFractionDigits="2" :useGrouping="false" style="width: 90px;" placeholder="填写尺寸" v-model="form.goukuan"
                          :class="{'p-invalid':(v$.form.goukuan.$invalid && submitted),'p-disabled':state===5}"></InputNumber>
                        <label class="p-col-fixed" style="justify-content: flex-end;margin-top: 5px">cm</label>
                      </div>
                    </div>
                  </div>
                  <div class="p-field p-grid colee" style="margin-bottom: 15px;">
                    <label class="p-col-fixed" style="width:74px;justify-content: flex-end;margin-top: 4px;">珠间距</label>
                    <div class="p-col colee">
                      <InputNumber :minFractionDigits="2" :useGrouping="false" style="width: 90px;" placeholder="填写尺寸" v-model="form.zhujianju"
                        :class="{'p-invalid':(v$.form.zhujianju.$invalid && submitted),'p-disabled':state===5}"></InputNumber>
                      <label class="p-col-fixed" style="width:74px;justify-content: flex-end;margin-top: 5px">cm</label>
                      <div class="p-col colee">
                        <label class="p-col-fixed" style="width:96px;justify-content: flex-end;margin-top: 8px;">沟深(穴深)</label>
                        <InputNumber :minFractionDigits="2" :useGrouping="false" style="width: 90px;" placeholder="填写尺寸" v-model="form.goushen"
                          :class="{'p-invalid':(v$.form.goushen.$invalid && submitted),'p-disabled':state===5}"></InputNumber>
                        <label class="p-col-fixed" style="justify-content: flex-end;margin-top: 5px">cm</label>
                      </div>
                    </div>
                  </div>
                  <span v-if="(v$.form.hangjianju.required.$invalid || v$.form.goukuan.required.$invalid || v$.form.zhujianju.required.$invalid || v$.form.goushen.required.$invalid) && submitted"
                    style="font-size: 12px;color: red;margin-left: 74px">请输入完整的整地建议！</span>
                </div>
              </div>
              <div class="p-field p-grid" style="margin-bottom: 0">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">栽培方法:</label>
                <div class="p-col colee" style="margin-left: -4px;height: 21px;">
                  <p v-for="(item,index) in allList" :key="index">
                    <RadioButton :id="'l'+index" name="menu" :value="item.name" v-model="form.method" :class="{'p-invalid':(v$.form.method.$invalid && submitted),'p-disabled':state===5}" />
                    <label :class="{'p-disabled':state===5}" :for="'l'+index" style="margin-left: 4px;">{{
                        item.name
                      }}</label>
                  </p>
                </div>
              </div>
              <span v-if="v$.form.method.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 118px">请点击栽培方法！</span>
              <div class="p-field p-grid" style="margin-bottom: 0;margin-top: 31px;">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">推荐肥料:</label>
                <div class="colee" style="width: 478px;flex-wrap: wrap;margin-left: 3px;height: 21px;">
                  <p v-for="(item,index) in allLists" :key="index" @click="falss(item)">
                    <RadioButton :id="'n'+index" name="menu" :value="item.name" v-model="form.fertilizerType" :class="{'p-invalid':(v$.form.fertilizerType.$invalid && submitted),'p-disabled':state===5}" />
                    <label :class="{'p-disabled':state===5}" :for="'n'+index" style="margin-left: 4px;">{{
                        item.name
                      }}</label>
                  </p>
                  <label v-if="state!==5&&form.fertilizerType=='厂家'" class="p-col-fixed" style="cursor:pointer;color: #24bd7f" @click="addOrUpdate">
                    <div class="yuan"><i class="pi pi-plus"></i></div>
                    添加推荐肥料
                  </label>
                </div>
              </div>
              <span v-if="v$.form.fertilizerType.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 118px">请点击推荐肥料！</span>
              <div class="p-field p-grid" style="margin-top: 32px;width: 584px;margin-left: 42px;" v-if="baal">
                <DataTable :value="addc" :row-hover="true" v-model:selection="addcs">
                  <Column field="name" header="肥料名称"></Column>
                  <Column field="type" header="分类"></Column>
                  <Column frozen alignFrozen="right" :exportable="false" header="数量" style="min-width:20rem;white-space: nowrap">
                    <template #body="data">
                      <InputNumber :minFractionDigits="0" :useGrouping="false" v-model="data.data.num" :id="'F'+data.data.id" placeholder="输入数字" :class="{'p-disabled':state===5}" style="width: 76px;"
                        @blur="blur(data.data)" data=data></InputNumber>
                      <label class="p-col-fixed">公斤</label>
                    </template>
                  </Column>
                  <Column v-if="state!==5" frozen alignFrozen="right" :exportable="false" header="操作" style="min-width:10rem;">
                    <template #body="data">
                      <a style="padding:0 8px;color: rgb(36, 189, 127)" href="javascript:" class="a-button s-button" @click="shanchu(data.data)">删除</a>
                    </template>
                  </Column>
                </DataTable>
              </div>
              <div class="p-field p-grid" style="margin-top: 32px;">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">选择服务:</label>
                <div class="p-col" style="margin-left: -3px;">
                  <p v-for="(item,index) in process" :key="index">
                    <Checkbox :id="'c'+index" name="menu" :value="item.name" v-model="form.service" :class="{'p-invalid':(v$.form.service.$invalid && submitted),'p-disabled':state===5}" />
                    <label :class="{'p-disabled':state===5}" :for="'c'+index" style="margin-left: 4px;">{{
                        item.name
                      }}</label>
                  </p>
                  <span v-if="v$.form.service.required.$invalid && submitted" style="font-size: 12px;color: red;display: block;margin-bottom: 6px;">请选择服务！</span>
                  <label class="p-col-fixed" v-if="state!==5" style="cursor:pointer;justify-content: flex-end;margin-left: -7px;color: #24bd7f" @click="addOrUpdate2">
                    <div class="yuan"><i class="pi pi-plus"></i></div>
                    添加自动化机械
                  </label>
                </div>
              </div>
              <div class="p-field p-grid" style="margin-top: 32px;width: 575px;margin-left: 42px;" v-if="baal2">
                <DataTable :value="addb" :row-hover="true" v-model:selection="addbs">
                  <Column field="name" header="机械名称"></Column>
                  <Column field="type" header="分类"></Column>
                  <Column frozen alignFrozen="right" :exportable="false" header="数量" style="min-width:15rem;">
                    <template #body="data">
                      <InputNumber :minFractionDigits="0" v-model="data.data.num" :class="{'p-disabled':state===5}" :useGrouping="false" :id="'Z'+data.data.id" placeholder="输入数字" style="width: 76px;"
                        @blur="blur2(data.data)"></InputNumber>
                      <label class="p-col-fixed">辆</label>
                    </template>
                  </Column>
                  <Column v-if="state!==5" frozen alignFrozen="right" :exportable="false" header="操作" style="min-width:15rem;">
                    <template #body="data">
                      <a style="padding:0 8px;color: rgb(36, 189, 127)" href="javascript:" class="a-button s-button" @click="shanchu2(data.data)">删除</a>
                    </template>
                  </Column>
                </DataTable>
              </div>
              <div class="p-field p-grid">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">劳动力预估:</label>
                <div class="p-col colee">
                  <InputNumber :minFractionDigits="0" :useGrouping="false" placeholder="请输入预估人数" v-model="form.laborForce" :class="{'p-invalid':(v$.form.laborForce.$invalid && submitted),'p-disabled':state===5}">
                  </InputNumber>
                  <label style="margin-left: 10px;margin-top: 6px;">人/天</label>
                </div>
              </div>
              <span v-if="v$.form.laborForce.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 118px;">请输入劳动力预估！</span>
              <div class="p-field p-grid" style="margin-top: 31px;" v-if="state!==5">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">部署传感器建议:</label>
                <div class="p-col colee">
                  <label class="p-col-fixed" style="cursor:pointer;justify-content: flex-end;margin-left: -7px;color: #24bd7f" @click="addOrUpdate3">
                    <div class="yuan"><i class="pi pi-plus"></i></div>
                    添加建议传感器
                  </label>
                </div>
              </div>
              <div class="p-field p-grid" style="margin-top: 32px;width: 575px;margin-left: 42px;" v-if="baal3">
                <DataTable :value="adda" :row-hover="true" v-model:selection="addas">
                  <Column field="name" header="传感器名称"></Column>
                  <Column field="type" header="分类"></Column>
                  <Column frozen alignFrozen="right" :exportable="false" header="数量" style="min-width:15rem;">
                    <template #body="data">
                      <InputNumber :minFractionDigits="0" v-model="data.data.num" :useGrouping="false" :id="'C'+data.data.id" placeholder="输入数字" :class="{'p-disabled':state===5}" style="width: 76px;"
                        @blur="blur3(data.data)"></InputNumber>
                      <label class="p-col-fixed">个</label>
                    </template>
                  </Column>
                  <Column v-if="state!==5" frozen alignFrozen="right" :exportable="false" header="操作" style="min-width:15rem;">
                    <template #body="data">
                      <a style="padding:0 8px;color: rgb(36, 189, 127)" href="javascript:" class="a-button s-button" @click="shanchu3(data.data)">删除</a>
                    </template>
                  </Column>
                </DataTable>
              </div>
              <div class="p-field p-grid">
                <label class="p-col-fixed" style="width:120px;display: flex;justify-content: flex-end;">总成本预估:</label>
                <div style="border: 1px solid #e7dbdb;width: 439px;margin-left: 8px;border-radius: 3px;">
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">土地预估</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" placeholder="请输入数字" style="width: 86px;" :class="{'p-disabled':state===5}" v-model="tdyg"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 10px;">元/亩/年</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">种苗+运费</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" placeholder="请输入数字" style="width: 86px;" :class="{'p-disabled':state===5}" v-model="zm"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">种植服务/人力成本</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="mzzfw"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">肥料+管理成本(有机肥+动力+人力)</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="fl"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">灌溉+管理成本(水+管线+人力)</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="gg"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;margin-bottom: 8px;">自动化机械</label>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">
                      <span style="margin-right: 28px;">--</span>
                      种植
                    </label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="zz"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">
                      <span style="margin-right: 28px;">--</span>
                      植保
                    </label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="zb"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">
                      <span style="margin-right: 28px;">--</span>
                      灌溉
                    </label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="ggs"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">收割成本</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="sgcb"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">租赁成本</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="zlcb"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="border-bottom: 1px solid #e7dbdb;padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">搬运&运输成本(产地-加工地)</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="by"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                  <div class="p-col coleess" style="padding: 4px 0 4px 0">
                    <label class="p-col-fixed" style="margin-top: 8px;">其他成本</label>
                    <div>
                      <InputNumber :minFractionDigits="2" :useGrouping="false" :class="{'p-disabled':state===5}" placeholder="请输入数字" style="width: 86px;" v-model="qt"></InputNumber>
                      <label class="p-col-fixed" style="margin-top: 8px;margin-right: 29px;">元/亩</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-field p-grid" style="margin-bottom: 0;">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">产量预估:</label>
                <div class="p-col colee">
                  <InputNumber placeholder="填写数量" style="width: 100px;" v-model="yield0" :minFractionDigits="2" :class="{'p-invalid':(v$.yield0.$invalid && submitted),'p-disabled':state===5}"></InputNumber>
                  <span style="margin: 0 1rem;line-height: 33px">--</span>
                  <InputNumber placeholder="填写数量" style="width: 100px;" v-model="yield1" :minFractionDigits="2"
                    :class="{'p-invalid':(submitted && yield1<yield0)||( v$.yield1.$invalid && submitted),'p-disabled':state===5}"></InputNumber>
                  <label style="margin-left: 10px;margin-top: 6px;">吨/亩</label>
                </div>
              </div>
              <span v-if="(v$.yield0.required.$invalid || v$.yield1.required.$invalid) && submitted" style="font-size: 12px;color: red;margin-left: 118px;">请输入产量预估！</span>
              <span v-if="yield1&& yield0&& yield0>yield1 && submitted" style="font-size: 12px;color: red;margin-left: 118px;">请输入正确产量预估！</span>
              <div class="p-field p-grid" style="margin-top: 31px;margin-bottom: 0;">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end">菌草加工建议:</label>
                <div class="p-col cols">
                  <InputText placeholder="填写菌草加工建议" v-model="form.procSuggest"  style="width: 200px;"
                    :class="{'p-invalid':(v$.form.procSuggest.$invalid && submitted),'p-disabled':state===5}"></InputText>
                  <!-- <Dropdown placeholder="填写菌草加工建议" v-model="form.procSuggest" :options="jiagun" optionLabel="name" option-value="name" style="width: 200px;"
                    :class="{'p-invalid':(v$.form.procSuggest.$invalid && submitted),'p-disabled':state===5}" /> -->
                </div>
              </div>
              <span v-if="v$.form.procSuggest.required.$invalid && submitted" style="font-size: 12px;color: red;margin-left: 118px;">请选择菌草加工建议！</span>
              <div class="p-field p-grid" v-if="state!==5" style="margin-bottom: 0;margin-top: 31px;">
                <label class="p-col-fixed" style="width:120px;justify-content: flex-end"></label>
                <div class="p-col colee">
                  <Button class="p-button-text" style="width: auto;margin-right: 15px;border: 1px solid #cccccc" @click="subt()">保存为草稿
                  </Button>
                  <Button style="width: auto;" @click="subs()">提交建议</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <addOrUpdatesuggest1 @say1="hyzx" ref="addOrUpo" v-if="addOrUpdatesuggest1" v-on:close="closeDialog1"></addOrUpdatesuggest1>
        <addOrUpdatesuggest2 @say2="funs2" ref="addOrUpt" v-if="addOrUpdatesuggest2" v-on:close="closeDialog2"></addOrUpdatesuggest2>
        <addOrUpdatesuggest3 @say3="funs3" ref="addOrUph" v-if="addOrUpdatesuggest3" v-on:close="closeDialog3"></addOrUpdatesuggest3>
        <Weather ref="Weatherww" v-if="Weather" v-on:close="Weathers"></Weather>
        <Sidebar v-model:visible="visibleLeft" style="width: 700px;">
          <ShowProject :title="false" :back-but="false" :id="form.planId"></ShowProject>
        </Sidebar>
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from "primevue/radiobutton";
import addOrUpdatesuggest1 from "./addOrUpdatesuggest1";
import addOrUpdatesuggest2 from "./addOrUpdatesuggest2";
import addOrUpdatesuggest3 from "./addOrUpdatesuggest3";
import Sidebar from "primevue/sidebar";
import ShowProject from "./showProject";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Weather from "./weather";

export default {
  name: "plan",
  components: {
    RadioButton,
    addOrUpdatesuggest1,
    addOrUpdatesuggest2,
    addOrUpdatesuggest3,
    Sidebar,
    ShowProject,
    Weather,
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      yield0: { required },
      yield1: { required },
      form: {
        type: { required },
        num: { required },
        bestTime: { required },
        hangjianju: { required }, //行间距
        goukuan: { required }, //沟宽
        zhujianju: { required }, //株间距
        goushen: { required }, //沟深
        method: { required },
        fertilizerType: { required },
        service: { required },
        laborForce: { required },
        yield: {},
        procSuggest: { required },
      },
    };
  },
  data() {
    return {
      tjfl: false,
      baal: false,
      baal2: false,
      baal3: false,
      visibleLeft: false,
      yield0: null,
      yield1: null,
      form: {
        id: null, //编辑id
        planId: Number(this.$route.query.Id), //项目编号
        type: null, //菌草品种
        num: null, //num//种苗数量
        bestTime: null, //最佳种植时间
        hangjianju: null, //行间距
        goukuan: null, //沟宽
        zhujianju: null, //株间距
        goushen: null, //沟深
        method: null, //method
        fertilizerType: null, //推荐肥料
        service: [], //service
        laborForce: null, //劳动力评估
        sensorList: [], //部署传感器建议
        fertilizerList: [], //部署肥料
        machineList: [], //部署机械
        yield: null, // 产量预估
        procSuggest: null, //菌草加工建议
        suggestCost: [],
        isDraft: 1,
        province: null,
        city: null,
      },
      fas: false,
      pingzs: [
        { id: 0, name: "巨菌草" },
        { id: 1, name: "绿洲一号" },
      ],
      // jiagun: [
      //   { id: 0, name: "巨菌草" },
      //   { id: 1, name: "记号记号" },
      //   { id: 2, name: "来看看尽快" },
      //   { id: 3, name: "鲜草草份-菌菇原料" },
      // ],
      valuess: this.$route.query.name,
      checkboxs: [],
      process: [
        { id: 0, name: "土地平整服务" },
        { id: 1, name: "种植服务" },
        { id: 2, name: "自动化机械" },
        { id: 3, name: "收割服务" },
      ],
      breadcrumb: {
        home: { label: "种植规划", to: "/plan" },
        items: [
          { label: "填写专家建议", to: "#" },
        ],
      },
      bools: false,
      bool: true,
      allList: [
        { id: 0, name: "短杆扦插" },
        { id: 1, name: "全珠条栽" },
        { id: 2, name: "育苗移栽" },
        { id: 3, name: "丛栽" },
      ],
      allLists: [
        { id: 1, name: "农家肥" },
        { id: 0, name: "厂家" },
      ],
      allLi: [
        { id: 0, name: "红壤" },
        { id: 1, name: "沙壤地" },
        { id: 2, name: "湿地(草甸/沼泽地)" },
        { id: 3, name: "黑土" },
        { id: 4, name: "盐碱地" },
      ],
      govern: null,
      liyong: null,
      addOrUpdatesuggest1: false,
      addOrUpdatesuggest2: false,
      addOrUpdatesuggest3: false,
      Weather: false,
      current_type: 0,
      adda: [],
      addb: [],
      addc: [],
      addcs: null,
      addbs: null,
      addas: null,
      asa: null,
      edfr3: null,
      edfr2: null,
      edfr1: null,
      tdyg: null,
      zm: null,
      mzzfw: null,
      fl: null,
      gg: null,
      zz: null,
      zb: null,
      ggs: null,
      sgcb: null,
      zlcb: null,
      by: null,
      qt: null,
      inpadc: {},
      inpadc2: {},
      inpadc3: {},
      yuef: [
        { id: 1, name: "一月" },
        { id: 2, name: "二月" },
        { id: 3, name: "三月" },
        { id: 4, name: "四月" },
        { id: 5, name: "五月" },
        { id: 6, name: "六月" },
        { id: 7, name: "七月" },
        { id: 8, name: "八月" },
        { id: 9, name: "九月" },
        { id: 10, name: "十月" },
        { id: 11, name: "十一月" },
        { id: 12, name: "十二月" },
      ],
      submitted: false,
      id: null,
      data: null,
      val1: null,
      val2: null,
      val3: null,
      state: null,
    };
  },
  mounted() {
    let that = this;
    this.id = this.$route.query.Id;
    this.state = Number(this.$route.query.sta);

    this.$http(
      "/suggest/getByPlanId",
      "get",
      {
        id: this.id,
      },
      function (res) {
        that.data = res.data;
        if (res.data) {
          that.form = res.data;
          //console.log(that.form)
          let hh = Number(that.form.bestTime);
          that.form.bestTime = hh;

          if (that.form.service) {
            if (that.form.service.indexOf(",") > -1) {
              that.form.service = that.form.service.split(",");
            } else {
              that.form.service = [that.form.service];
            }
          }
          that.breadcrumb.items[0].label = "修改专家建议";
          if (that.state === 5) {
            that.breadcrumb.items[0].label = "查看专家建议";
          }

          if (res.data.fertilizerList && res.data.fertilizerList.length > 0) {
            that.baal = true;
            that.addc = JSON.parse(JSON.stringify(res.data.fertilizerList));
            that.addc.forEach((item, index) => {
              that.addc[index].index = index;
            });
          } else {
            that.baal = false;
          }
          if (res.data.machineList && res.data.machineList.length > 0) {
            that.baal2 = true;
            that.addb = JSON.parse(JSON.stringify(res.data.machineList));
            that.addb.forEach((item, index) => {
              that.addb[index].index = index;
            });
          } else {
            that.baal2 = false;
          }
          if (res.data.sensorList && res.data.sensorList.length > 0) {
            that.baal3 = true;
            that.adda = JSON.parse(JSON.stringify(res.data.sensorList));
            that.adda.forEach((item, index) => {
              that.adda[index].index = index;
            });
          } else {
            that.baal3 = false;
          }

          if (res.data.yield) {
            that.yield0 = res.data.yield.split("-")[0];
            that.yield1 = res.data.yield.split("-")[1];
          }

          for (let i = 0; i < res.data.suggestCost.length; i++) {
            if (res.data.suggestCost[i].item == "土地预估") {
              that.tdyg = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "种苗+运费") {
              that.zm = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "种植服务/人力成本") {
              that.mzzfw = res.data.suggestCost[i].price;
            }
            if (
              res.data.suggestCost[i].item == "肥料+管理成本(有机肥+动力+人力)"
            ) {
              that.fl = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "灌溉+管理成本(水+管线+人力)") {
              that.gg = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "种植") {
              that.zz = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "种植") {
              that.zb = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "灌溉") {
              that.ggs = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "收割成本") {
              that.sgcb = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "租赁成本") {
              that.zlcb = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "搬运&运输成本(产地-加工地)") {
              that.by = res.data.suggestCost[i].price;
            }
            if (res.data.suggestCost[i].item == "其他成本") {
              that.qt = res.data.suggestCost[i].price;
            }
          }
        }
      }
    );
  },
  methods: {
    falss(item) {
      if (item.id == 0) {
        this.tjfl = true;
      } else {
        this.tjfl = false;
      }
    },
    addOrUpdate() {
      this.addOrUpdatesuggest1 = true;
      this.$nextTick(() => {
        this.$refs.addOrUpo.init(this.addc);
      });
    },
    addOrUpdate2() {
      this.addOrUpdatesuggest2 = true;
      this.$nextTick(() => {
        this.$refs.addOrUpt.init(this.addb);
      });
    },
    addOrUpdate3() {
      this.addOrUpdatesuggest3 = true;
      this.$nextTick(() => {
        this.$refs.addOrUph.init(this.adda);
      });
    },
    Weatheraa() {
      let province = this.$route.query.province
        ? this.$route.query.province
        : this.form.province
        ? this.form.province
        : null;
      let city = this.$route.query.city
        ? this.$route.query.city
        : this.form.city
        ? this.form.city
        : null;
      this.Weather = true;
      //console.log(this.form.bestTime)
      this.$nextTick(() => {
        this.$refs.Weatherww.init(province, city, this.form.bestTime);
      });
    },
    closeDialog1() {
      this.addOrUpdatesuggest1 = false;
    },
    closeDialog2() {
      this.addOrUpdatesuggest2 = false;
    },
    closeDialog3() {
      this.addOrUpdatesuggest3 = false;
    },
    Weathers() {
      this.Weather = false;
    },
    submit() {
      this.form.bestTime
        ? (this.form.bestTime = this.form.bestTime.toString())
        : null;
      this.hangjianju
        ? (this.form.hangjianju = Number(this.form.hangjianju))
        : null;
      this.form.goukuan
        ? (this.form.goukuan = Number(this.form.goukuan))
        : null;
      this.form.zhujianju
        ? (this.form.zhujianju = Number(this.form.zhujianju))
        : null;
      this.form.goushen
        ? (this.form.goushen = Number(this.form.goushen))
        : null;
      this.form.laborForce
        ? (this.form.laborForce = Number(this.form.laborForce))
        : null;
      //this.form.yield ? this.form.yield = Number(this.form.yield) : null
      this.form.num ? (this.form.num = Number(this.form.num)) : null;
      this.form.service
        ? (this.form.service = this.form.service.toString())
        : null;

      this.form.fertilizerList = [];
      for (let i = 0; i < this.addc.length; i++) {
        this.form.fertilizerList.push({
          fertilizerId: this.addc[i].id,
          num: this.addc[i].num,
        });
      }
      this.form.machineList = [];
      for (let i = 0; i < this.addb.length; i++) {
        this.form.machineList.push({
          machineId: this.addb[i].id,
          num: this.addb[i].num,
        });
      }

      this.form.sensorList = [];
      for (let i = 0; i < this.adda.length; i++) {
        this.form.sensorList.push({
          sensorId: this.adda[i].id,
          num: this.adda[i].num,
        });
      }
      this.form.yield = this.yield0 + "-" + this.yield1;

      this.form.suggestCost = [];
      this.tdyg
        ? this.form.suggestCost.push({
            item: "土地预估",
            price: this.tdyg,
            unit: "元/亩/年",
          })
        : null;
      this.zm
        ? this.form.suggestCost.push({
            item: "种苗+运费",
            price: this.zm,
            unit: "元/亩",
          })
        : null;
      this.mzzfw
        ? this.form.suggestCost.push({
            item: "种植服务/人力成本",
            price: this.mzzfw,
            unit: "元/亩",
          })
        : null;
      this.fl
        ? this.form.suggestCost.push({
            item: "肥料+管理成本(有机肥+动力+人力)",
            price: this.fl,
            unit: "元/亩",
          })
        : null;
      this.gg
        ? this.form.suggestCost.push({
            item: "灌溉+管理成本(水+管线+人力)",
            price: this.gg,
            unit: "元/亩",
          })
        : null;
      this.zz
        ? this.form.suggestCost.push({
            item: "种植",
            price: this.zz,
            unit: "元/亩",
          })
        : null;
      this.zb
        ? this.form.suggestCost.push({
            item: "植保",
            price: this.zb,
            unit: "元/亩",
          })
        : null;
      this.ggs
        ? this.form.suggestCost.push({
            item: "灌溉",
            price: this.ggs,
            unit: "元/亩",
          })
        : null;
      this.sgcb
        ? this.form.suggestCost.push({
            item: "收割成本",
            price: this.sgcb,
            unit: "元/亩",
          })
        : null;
      this.zlcb
        ? this.form.suggestCost.push({
            item: "租赁成本",
            price: this.zlcb,
            unit: "元/亩",
          })
        : null;
      this.by
        ? this.form.suggestCost.push({
            item: "搬运&运输成本(产地-加工地)",
            price: this.by,
            unit: "元/亩",
          })
        : null;
      this.qt
        ? this.form.suggestCost.push({
            item: "其他成本",
            price: this.qt,
            unit: "元/亩",
          })
        : null;

      this.$http(
        "/suggest/addSuggest",
        "post",
        this.form,
        (res) => {
          this.$toast.add({
            severity: "success",
            summary: "建议提交成功",
            life: 3000,
          });
          this.$router.push("/plan");
        },
        (e) => {
          console.log(e);
        }
      );
    },
    subt() {
      let numIsNullList = [];
      if (this.addc.length > 0) {
        for (let i = 0; i < this.addc.length; i++) {
          if (!this.addc[i].num) {
            numIsNullList.push("F" + this.addc[i].id);
          }
        }
      }
      if (this.addb.length > 0) {
        for (let i = 0; i < this.addb.length; i++) {
          if (!this.addb[i].num) {
            numIsNullList.push("Z" + this.addb[i].id);
          }
        }
      }
      if (this.adda.length > 0) {
        for (let i = 0; i < this.adda.length; i++) {
          if (!this.adda[i].num) {
            numIsNullList.push("C" + this.adda[i].id);
          }
        }
      }
      if (numIsNullList && numIsNullList.length > 0) {
        numIsNullList.forEach((id) => {
          document.getElementById(id).style.borderColor = "red";
        });
        this.$toast.add({
          severity: "warn",
          summary: "请检查表单信息完整性",
          life: 3000,
        });
        return;
      }

      this.submitted = false;
      this.form.isDraft = 0;
      this.submit();
    },

    subs() {
      this.submitted = true;
      if (this.v$.$invalid) {
        this.$toast.add({
          severity: "warn",
          summary: "请检查表单信息完整性",
          life: 3000,
        });
        return;
      }
      let numIsNullList = [];
      if (this.addc.length > 0) {
        for (let i = 0; i < this.addc.length; i++) {
          if (!this.addc[i].num) {
            numIsNullList.push("F" + this.addc[i].id);
          }
        }
      }
      if (this.addb.length > 0) {
        for (let i = 0; i < this.addb.length; i++) {
          if (!this.addb[i].num) {
            numIsNullList.push("Z" + this.addb[i].id);
          }
        }
      }
      if (this.adda.length > 0) {
        for (let i = 0; i < this.adda.length; i++) {
          if (!this.adda[i].num) {
            numIsNullList.push("C" + this.adda[i].id);
          }
        }
      }
      if (numIsNullList && numIsNullList.length > 0) {
        numIsNullList.forEach((id) => {
          document.getElementById(id).style.borderColor = "red";
        });
        this.$toast.add({
          severity: "warn",
          summary: "请检查表单信息完整性",
          life: 3000,
        });
        return;
      }
      if (this.yield0 > this.yield1) {
        this.$toast.add({
          severity: "warn",
          summary: "请检产量填写内容",
          life: 3000,
        });
        return;
      }

      this.form.isDraft = 1;
      this.submit();
    },
    hyzx(val) {
      this.val = val;
      for (let i = 0; i < val.length; i++) {
        val[i].index = i;
      }
      if (val != null) {
        this.baal = true;
        this.addc = val;
      } else {
        this.baal = false;
      }
    },
    funs2(val) {
      this.val2 = val;
      for (let i = 0; i < val.length; i++) {
        val[i].index = i;
      }

      if (val != null) {
        this.baal2 = true;
        this.addb = val;
      } else {
        this.baal2 = false;
      }
    },
    funs3(val) {
      this.val3 = val;
      for (let i = 0; i < val.length; i++) {
        val[i].index = i;
      }

      if (val != null) {
        this.baal3 = true;
        this.adda = val;
      } else {
        this.baal3 = false;
      }
    },
    shanchu(v) {
      let color = this.addc.filter(function (item) {
        return item.id != v.id;
      });
      this.addc = color;
      if (this.addc.length == 0) {
        this.baal = false;
      }
    },
    shanchu2(v) {
      let color = this.addb.filter(function (item) {
        return item.id != v.id;
      });
      this.addb = color;
      if (this.addb.length == 0) {
        this.baal2 = false;
      }
    },
    shanchu3(v) {
      let color = this.adda.filter(function (item) {
        return item.id != v.id;
      });
      //console.log(color)
      this.adda = color;
      if (this.adda.length == 0) {
        this.baal3 = false;
      }
    },
    blur(data) {
      let input = document.getElementById("F" + data.id);

      if (input.value) {
        let value = input.value;
        this.inpadc[data.id] = value;
        this.addc[data.index]["num"] = value;
        input.style.border = "1px solid #ced4da";
      }
    },
    blur2(data) {
      let input2 = document.getElementById("Z" + data.id);
      let value = input2.value;
      if (value) {
        this.inpadc2[data.id] = value;
        this.addb[data.index]["num"] = value;
        input2.style.border = "1px solid #ced4da";
      }
    },
    blur3(data) {
      let input3 = document.getElementById("C" + data.id);
      let value = input3.value;
      if (value) {
        this.inpadc3[data.id] = value;
        this.adda[data.index]["num"] = value;
        input3.style.border = "1px solid #ced4da";
      }
    },
  },
};
</script>

<style scoped>
.cont {
  width: 600px;
  margin: 0 auto;
  padding-top: 20px;
}

::v-deep(.p-tabmenu) {
  width: 265px;
  margin: 0 auto;
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
}

::v-deep(.p-fluid .p-inputnumber) {
  width: 91%;
}

::v-deep(.p-button.p-button-text:enabled:hover) {
  color: rgb(36, 189, 127);
}

.inp {
  width: 110px;
  margin-bottom: 5px;
}

.cols {
  display: flex;
  justify-content: space-between;
}

.colee {
  display: flex;
  justify-content: flex-start;
}

.colee > p {
  margin-right: 20px;
}

.tianq {
  border: 1px solid #4dbb2cff;
  width: 26px;
  height: 26px;
  margin: 6px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  line-height: 26px;
}

.yuan {
  display: inline-block;
  border: 1px solid #24bd7f;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
}

.coleess {
  display: flex;
  justify-content: space-between;
}

.xunh {
  border-bottom: 1px solid black;
  padding: 4px 0 4px 0;
}

.xungs {
  border: 1px solid black;
  width: 439px;
  margin-left: 8px;
}
</style>
